import React from "react";
import Button from "devextreme-react/button";
import DataGrid, { Column, Editing, Paging, Lookup, Export, Pager, FilterRow, FormItem } from "devextreme-react/data-grid";
import { renderTitleHeader } from "../utils/common-rendering-funcs";
import { StringLengthRule, RequiredRule, CustomRule } from "devextreme-react/validator";
import { CheckBox } from "devextreme-react/check-box";
import { Row, Col, CardHeader, Card, CardBody } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import notify from "devextreme/ui/notify";
import {
  post_priority_dates,
  delete_priority_dates,
  get_priority_dates_for_sport,
  get_blocked_dates_for_sport,
  get_game_dates_for_teams_in_date_range,
} from "../services/privateService";
import { isString, utc2db, getMinMaxDateValues, getDateSequence, makeClinetFriendlySeasonName } from "../utils/common-funcs";
import { time_format, blockedForId_lookup, pager_lengths, dateTypeId_lookup, timeBlockType_lookup } from "./../utils/enums";
import { parse, format } from "fecha";
import Tabs from "devextreme-react/tabs";

const isPrivateDataSource = [
  {
    id: 1,
    name: "Private",
  },
  {
    id: 0,
    name: "Public",
  },
];

const endHour = 21;
const startHour = 1;

const tabs = [
  {
    id: 1,
    text: "Facility Conflicts/Priority Dates",
    title: "Facility Conflicts/Priority Dates",
  },
  {
    id: 0,
    text: "Facility Conflicts/Priority Date Entry",
    title: "Add priority dates by clicking + on top-right corner of table.",
  },
];

class DateManager extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 1,
      priority_dates_table: [],
      all_priority_dates_table: [],
      table_has_new_rows: false,
      minDateValue: null,
      maxDateValue: null,
      usePopupEditinMode: true,
    };

    this.onEditorPreparing = this.onEditorPreparing.bind(this);
    this.onTabsSelectionChanged = this.onTabsSelectionChanged.bind(this);
    this.onValueChangedUsePopupEditinMode = this.onValueChangedUsePopupEditinMode.bind(this);
    this.onClickRefresh = this.onClickRefresh.bind(this);
  }

  async componentDidMount() {
    if (this.props.isAuthenticated && this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      await this.populate_data_table();
    }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (this.props.user && !prevProps.user) ||
      (!this.props.user && prevProps.user) ||
      this.props.user.hq_current_user.userId !== prevProps.user.hq_current_user.userId ||
      this.props.isAuthenticated !== prevProps.isAuthenticated ||
      prevProps.selectedDivision.id !== this.props.selectedDivision.id ||
      prevProps.selectedTeam.id !== this.props.selectedTeam.id ||
      prevProps.selectedSport.id !== this.props.selectedSport.id ||
      prevProps.selectedSeason.id !== this.props.selectedSeason.id
    ) {
      await this.populate_data_table();
    }
  }

  onTabsSelectionChanged(args) {
    if (args.name === "selectedIndex" && args.value !== this.state.selectedIndex) {
      this.setState(
        {
          selectedIndex: args.value,
        },
        async () => {
          await this.refresh_data_sources();
        }
      );
    }
  }

  async onClickRefresh(e) {
    await this.refresh_data_sources();
  }

  onValueChangedUsePopupEditinMode(args) {
    const tableToForceRefrsh = [...this.state.priority_dates_table];
    this.setState({
      usePopupEditinMode: args.value,
      priority_dates_table: tableToForceRefrsh,
    });
  }

  refresh_data_sources = async () => {
    if (this.props.selectedTeam.id && this.props.selectedDivision.id && this.props.selectedSport.id && this.props.selectedSeason.id) {
      try {
        console.log("refresh_data_sources");
        await this.populate_data_table();
      } catch (error) {
        console.log(error);
      }
    }
  };

  populate_data_table = async () => {
    notify(`Refreshing table`);
    try {
      if (this.props.isAuthenticated && this.props.auth0jwt && this.props.selectedSport.id && this.props.selectedSeason.id && this.props.selectedDivision.id) {
        const [minDateValue, maxDateValue] = getMinMaxDateValues(this.props.selectedSport.name, this.props.selectedSeason.name);

        let { data: priority_array } = await get_priority_dates_for_sport(
          this.props.auth0jwt,
          this.props.selectedSeason.id,
          0,
          this.props.selectedSport.id,
          this.props.selectedDivision.id
        );

        let { data: blocked_array } = await get_blocked_dates_for_sport(
          this.props.auth0jwt,
          this.props.selectedSeason.id,
          0,
          this.props.selectedSport.id,
          this.props.selectedDivision.id
        );

        if (!Array.isArray(priority_array)) {
          priority_array = [];
        }

        if (!Array.isArray(blocked_array)) {
          blocked_array = [];
        }

        const all_priority_dates_table = [...priority_array, ...blocked_array];

        if (this.props.selectedTeam.id) {
          const priority_dates_table = all_priority_dates_table.filter((x) => x.divisionId === this.props.selectedDivision.id && x.teamId === this.props.selectedTeam.id);

          this.setState({
            all_priority_dates_table: all_priority_dates_table || [],
            priority_dates_table: priority_dates_table || [],
            table_has_new_rows: true,
            minDateValue: minDateValue,
            maxDateValue: maxDateValue,
          });
        } else {
          this.setState({
            all_priority_dates_table: all_priority_dates_table || [],
            priority_dates_table: [],
            table_has_new_rows: false,
            minDateValue: minDateValue,
            maxDateValue: maxDateValue,
          });
        }
      } else {
        this.setState({ all_priority_dates_table: [], priority_dates_table: [], table_has_new_rows: false });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onInitNewRow = async (e) => {
    e.promise = this.checkTokenLocal();

    let startDate = new Date();
    let endDate = new Date();
    startDate.setHours(startHour, 0, 0, 0);
    endDate.setHours(endHour, 0, 0, 0);
    e.data.teamId = this.props.selectedTeam ? this.props.selectedTeam.id : 0;
    e.data.seasonId = this.props.selectedSeason ? this.props.selectedSeason.id : 0;
    e.data.displaySportId = this.props.selectedSport ? this.props.selectedSport.id : 0;
    e.data.divisionId = this.props.selectedDivision ? this.props.selectedDivision.id : 0;
    e.data.userId = this.props.user.hq_current_user.userId;
    e.data.sub = this.props.user.sub;
    e.data.isPrivate = 0;
    e.data.blockedForId = 2;
    e.data.userName = this.props.user.email;
    e.data.startDate = startDate.toISOString();
    e.data.endDate = startDate.toISOString();
    e.data.lastUpdate = new Date().toISOString();
    e.data.dateId = 0;
    e.data.timeBlockTypeId = 3;
  };

  make_priority_date_object_for_db = (deep_copy) => {
    if (!deep_copy) {
      return deep_copy;
    }

    if (isString(deep_copy.startDate)) {
      deep_copy.startDateIso = deep_copy.startDate;
      deep_copy.startDate = utc2db(deep_copy.startDate);
    } else {
      deep_copy.startDate.setHours(startHour, 0, 0, 0);
      const startIso = deep_copy.startDate.toISOString();
      deep_copy.startDateIso = startIso;
      deep_copy.startDate = utc2db(startIso);
    }

    if (isString(deep_copy.endDate)) {
      deep_copy.endDateIso = deep_copy.endDate;
      deep_copy.endDate = utc2db(deep_copy.endDate);
    } else {
      deep_copy.endDate.setHours(endHour, 0, 0, 0);
      const endIso = deep_copy.endDate.toISOString();
      deep_copy.endDateIso = endIso;
      deep_copy.endDate = utc2db(endIso);
    }

    deep_copy["lastUpdate"] = utc2db(new Date().toISOString());
    deep_copy["userId"] = this.props.user.hq_current_user.userId;
    deep_copy["sub"] = this.props.user.sub;
    deep_copy["userName"] = this.props.user.email;
    return deep_copy;
  };

  get_scheduled_game_conflicts = async (deep_copy) => {
    try {
      let open_bounds_array_yyyymdd = [];
      if (deep_copy.startDateIso && deep_copy.endDateIso) {
        let leftBoundOpen = parse(deep_copy.startDateIso, time_format.utc);
        let rightBoundOpen = parse(deep_copy.endDateIso, time_format.utc);

        leftBoundOpen.setDate(leftBoundOpen.getDate() - 1);
        rightBoundOpen.setDate(rightBoundOpen.getDate() + 1);

        leftBoundOpen = format(leftBoundOpen, time_format.yyymmdd);
        rightBoundOpen = format(rightBoundOpen, time_format.yyymmdd);
        open_bounds_array_yyyymdd = [leftBoundOpen, rightBoundOpen];
      } else {
        return 0;
      }

      if (open_bounds_array_yyyymdd.length < 1) {
        return 0;
      }

      const leftBound_yyyymmdd = format(parse(deep_copy.startDateIso, time_format.utc), time_format.yyymmdd);
      const rightBound_yyyymmdd = format(parse(deep_copy.endDateIso, time_format.utc), time_format.yyymmdd);

      const { data: game_dates_array } = await get_game_dates_for_teams_in_date_range(
        this.props.auth0jwt,
        this.props.selectedDivision.id,
        this.props.selectedSport.id,
        this.props.selectedSeason.id,
        this.props.selectedTeam.id,
        open_bounds_array_yyyymdd
      );

      const existing_game_dates = [];
      for (let j = 0; j < game_dates_array.length; j++) {
        const game = format(parse(game_dates_array[j].date, time_format.utc), time_format.yyymmdd);

        if (game >= leftBound_yyyymmdd && game <= rightBound_yyyymmdd) {
          existing_game_dates.push(game);
        }
      }

      if (existing_game_dates.length > 0) {
        toast.info(`Games exist on the following date(s): ${existing_game_dates.join(", ")}`, {
          position: "top-center",
          autoClose: 9999,
          hideProgressBar: true,

          progress: 0,
        });
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.log(error);
      return 1;
    }
  };

  conflict_range_or_null = async (startDate, endDate, lastUpdate, existingTable) => {
    if (!Array.isArray(existingTable) || existingTable.length < 1) {
      return null;
    }

    let startDate_m = isString(startDate) ? parse(startDate, time_format.utc) : new Date(startDate);
    let endDate_m = isString(endDate) ? parse(endDate, time_format.utc) : new Date(endDate);

    const startDate_yyyymmdd = format(startDate_m, time_format.yyymmdd);
    const endDate_yyyymmdd = format(endDate_m, time_format.yyymmdd);

    for (let index = 0; index < existingTable.length; index++) {
      const row = existingTable[index];
      if (this.props.selectedTeam.id !== row.teamId || row.lastUpdate === lastUpdate) {
        //don't compare against other teams
        //dont compare against yourself (lastUpdate)
        continue;
      }

      const row_seq = getDateSequence(row.startDate, row.endDate, time_format.utc);
      for (let index = 0; index < row_seq.length; index++) {
        const yyymmdd = row_seq[index];

        if (startDate_yyyymmdd === yyymmdd || endDate_yyyymmdd === yyymmdd) {
          return `[${startDate_yyyymmdd}]-[${endDate_yyyymmdd}]`;
        }
      }
    }

    return null;
  };

  update_or_insert_priority_date_row_to_db = async (e, isInsert) => {
    let new_priority_dates_table = [];
    if (Array.isArray(this.state.priority_dates_table)) {
      new_priority_dates_table = [...this.state.priority_dates_table];
    }

    try {
      let deep_copy = this.make_priority_date_object_for_db(JSON.parse(JSON.stringify(e.data)));

      if (e.data.teamId && e.data.seasonId && e.data.displaySportId && e.data.startDate && e.data.endDate && e.data.divisionId) {
        if (isInsert) {
          const conlict_range = await this.conflict_range_or_null(e.data.startDate, e.data.endDate, e.data.lastUpdate, new_priority_dates_table);

          if (conlict_range) {
            toast.error(`${conlict_range} contains days that have existing assignments.`, {
              autoClose: false,
              progress: 0,
            });
            return new_priority_dates_table;
          }

          const has_game_conflicts = await this.get_scheduled_game_conflicts(deep_copy);
        }

        const res = await post_priority_dates(this.props.auth0jwt, deep_copy);

        if (res.status === 200) {
          toast.success(`Successfully posted date!`);
          new_priority_dates_table = res.data;
        } else {
          toast.error(`Could not post date`);
        }
      } else {
        toast.error(`Could not post date`);
      }
    } catch (error) {
      toast.error(`Could not post date`);
      console.log(error);
    }

    return new_priority_dates_table;
  };

  onRowInserting = async (e) => {
    e.cancel = this.checkTokenLocal();
  };

  onRowInserted = async (e) => {
    console.log("inserted row");
    await this.update_or_insert_priority_date_row_to_db(e, true);
    await this.refresh_data_sources();
  };

  onRowUpdating = async (e) => {
    console.log("onRowUpdating");
    e.cancel = this.checkTokenLocal();
  };

  checkTokenLocal = async (e) => {
    return await this.props.checkToken("Date Manager");
  };

  onRowUpdated = async (e) => {
    console.log("updated row");
    await this.update_or_insert_priority_date_row_to_db(e, false);
    await this.refresh_data_sources();
  };

  onRowRemoved = async (e) => {
    let new_priority_dates_table = [...this.state.priority_dates_table];
    console.log("removed row");
    try {
      let deep_copy = this.make_priority_date_object_for_db(JSON.parse(JSON.stringify(e.data)));

      var res = await delete_priority_dates(this.props.auth0jwt, deep_copy);
      if (res.status === 200) {
        toast.success(`Successfully removed date!`);
      } else {
        toast.error(`Could not remove date`);
      }
    } catch (error) {
      toast.error(`Could not remove date`);
      console.log(error);
    }

    await this.refresh_data_sources();
  };

  setStateValueStartDate(rowData, value) {
    if (value && value instanceof Date) {
      value.setHours(startHour, 0, 0, 0);
      let endDate = new Date(value);
      endDate.setHours(endHour, 0, 0, 0);
      rowData.endDate = endDate;
    }

    this.defaultSetCellValue(rowData, value);
  }

  setStateValueEndDate(rowData, value) {
    if (value && value instanceof Date) {
      value.setHours(endHour, 0, 0, 0);
    }

    this.defaultSetCellValue(rowData, value);
  }

  validateEndDateLaterThanStartDate(e) {
    const start = e.data.startDate && isString(e.data.startDate) ? parse(e.data.startDate, time_format.utc) : e.data.startDate;
    const end = e.value && isString(e.value) ? parse(e.value, time_format.utc) : e.value;
    return end >= start;
  }

  onEditorPreparing(e) {
    if (e.dataField === "startDate" || e.dataField === "endDate") {
      e.editorOptions.min = this.state.minDateValue;
      e.editorOptions.max = this.state.maxDateValue;
    }

    if (e.dataField === "dateTypeId" && e.row?.rowType === "data") {
      //console.log(e.row.data)
      e.editorOptions.disabled = e.row?.data?.dateId > 0; //do not allow user to change type, because we have bug in changing type
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer />
        <Card className="main-card my-2 mx-2 border border-primary rounded">
          <CardHeader className="card-header-tab">
            <Tabs dataSource={tabs} selectedIndex={this.state.selectedIndex} onOptionChanged={this.onTabsSelectionChanged} />
            <div style={{ float: "left" }} className="mt-3">
              {this.state.selectedIndex === 0 && <div className="card-header-title thick">Priority dates for {this.props.selectedSport.name}</div>}
              {this.state.selectedIndex === 1 && (
                <div className="card-header-title thick">
                  Please enter any facility conflicts or priority dates for [{this.props.selectedTeam.name} / {this.props.selectedSport.name}] the [
                  {makeClinetFriendlySeasonName(this.props.selectedSport.name, this.props.selectedSeason.name)}] season by clicking the + on the top-right corner of the table.
                  Priority dates are preferred days to play, open dates are available dates to play, and blocked dates are unavailable. Facility conflicts and blocked date
                  information will be used for the conference office to create your league schedules
                </div>
              )}
              <small>
                <div>
                  All times are represented in <u>{Intl.DateTimeFormat().resolvedOptions().timeZone}</u> time-zone
                </div>
                <div style={{ color: "red" }}>
                  {this.state.selectedIndex === 1 &&
                    "Only your accessible teams will be shown in the grid. Please consult 'User Profile and Access Level' page to review your accessible teams."}
                </div>
              </small>
            </div>

            <div style={{ float: "right" }} className="mt-3">
              <Button icon="refresh" hint="Refesh Table" type="back" stylingMode="contained" onClick={this.onClickRefresh} />
            </div>
          </CardHeader>
          <CardBody className="pt-1">
            <Row className="px-2 pt-1">
              <Col xs={{ size: 4 }}>
                <CheckBox value={this.state.usePopupEditinMode} onValueChanged={this.onValueChangedUsePopupEditinMode} width={160} text="Use Popup editing" />
              </Col>
            </Row>
            <Row className="mb-5">
              <Col xs="12">
                {(this.state.selectedIndex === 1 || this.state.selectedIndex === 0) && (
                  <DataGrid
                    id="priority-dates-grid-container"
                    width={"100%"}
                    columnAutoWidth={true}
                    dataSource={this.state.selectedIndex === 0 ? this.state.all_priority_dates_table : this.state.priority_dates_table}
                    //keyExpr={["teamId", "seasonId", "displaySportId", "startDate", "endDate"]}
                    allowColumnReordering={true}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    rowAlternationEnabled={true}
                    onInitNewRow={this.onInitNewRow}
                    onRowInserted={this.onRowInserted}
                    onRowInserting={this.onRowInserting}
                    onRowUpdated={this.onRowUpdated}
                    onRowUpdating={this.onRowUpdating}
                    onRowRemoved={this.onRowRemoved}
                    onEditorPreparing={this.onEditorPreparing}
                    selection={{ mode: "multiple", showCheckBoxesMode: "none" }}
                  >
                    <Export enabled={true} fileName={"KPI-Priority-Dates"} />
                    <Paging defaultPageSize={100} />
                    <Pager showPageSizeSelector={true} allowedPageSizes={pager_lengths} showInfo={true} showNavigationButtons={true} />
                    <FilterRow visible={true} />
                    <Editing
                      mode={this.state.usePopupEditinMode ? "popup" : "row"}
                      useIcons={true}
                      allowUpdating={this.state.selectedIndex === 1}
                      allowDeleting={this.state.selectedIndex === 1}
                      allowAdding={this.state.selectedIndex === 1}
                    />
                    {/* <Editing mode="row" useIcons={true} allowUpdating={true} allowDeleting={true} allowAdding={true} /> */}
                    <Column dataField="divisionId" caption="Division" allowEditing={false} fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                      {/* divisionId is set from active set */}
                      <Lookup dataSource={this.props.divisions} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Division selection is required" />
                    </Column>
                    <Column dataField="teamId" caption="Team" allowEditing={false} fixed={true} fixedPosition="left" headerCellRender={renderTitleHeader}>
                      {/* teamId is set from active set */}
                      <Lookup dataSource={this.props.teams} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Team selection is required" />
                    </Column>
                    <Column dataField="blockedForId" caption="For" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={blockedForId_lookup} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Blocked-for selection is required" />
                    </Column>
                    <Column dataField="dateTypeId" caption="Date Type" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={dateTypeId_lookup} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Date type selection is required" />
                    </Column>

                    <Column dataField="startDate" caption="Start date" dataType="date" setCellValue={this.setStateValueStartDate} headerCellRender={renderTitleHeader}>
                      <RequiredRule message="Start date is required" />
                    </Column>
                    <Column dataField="endDate" caption="End date" dataType="date" sortOrder="asc" setCellValue={this.setStateValueEndDate} headerCellRender={renderTitleHeader}>
                      <RequiredRule message="End date is required" />
                      <CustomRule validationCallback={this.validateEndDateLaterThanStartDate} message="Start date is after End date" />
                    </Column>

                    {/* <Column dataField="isPrivate" caption="Privacy" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={isPrivateDataSource} displayExpr="name" valueExpr="id" />
                      <RequiredRule message="Privacy selection is required" />
                    </Column> */}
                    <Column dataField="note" caption="Reason" headerCellRender={renderTitleHeader}>
                      <StringLengthRule min={3} message="Reason should be at least 3 characters long" />
                      <FormItem colSpan={2} editorType="dxTextArea" />
                    </Column>
                    <Column dataField="additionalNotes" caption="Additional Notes" headerCellRender={renderTitleHeader}>
                      <FormItem colSpan={2} editorType="dxTextArea" />
                    </Column>
                    <Column dataField="venueName" caption="Venue Name" headerCellRender={renderTitleHeader}>
                      <FormItem colSpan={1} editorType="dxTextArea" />
                    </Column>
                    <Column dataField="timeBlockTypeId" caption="Time Slot" headerCellRender={renderTitleHeader}>
                      <Lookup dataSource={timeBlockType_lookup} displayExpr="name" valueExpr="id" />
                    </Column>
                    <Column dataField="userName" caption="Entered by" dataType="string" allowEditing={false} headerCellRender={renderTitleHeader}></Column>
                    <Column dataField="lastUpdate" caption="Last update" dataType="datetime" allowEditing={false} headerCellRender={renderTitleHeader}></Column>
                  </DataGrid>
                )}
              </Col>
            </Row>
            <Row>
              <small>{!this.state.usePopupEditinMode && <div>In row editing mode, use the tab key or shift+tab key to navigate between cells in a row</div>}</small>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default DateManager;
